import React from "react";
import Layout from "../components/Layout";

export default () => {
  return (
    <Layout>
      <h1 className="text-9xl">404.</h1>
      <p>Oops, the page you're looking for doesn't exist!</p>
    </Layout>
  );
};
